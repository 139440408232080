import React from "react";

import styles from "../../styles/HoldersInfo.module.css";
import purpleLink from "../../assets/images/pupleLink.png";
import FileIcon from "../../assets/images/fileIcon.svg";
import Score from "./Score";

export default function InfoRow({
  firstName,
  lastName,
  score,
  hideScore = false,
  link = false,
}) {
  return (
    <div className={styles.infoContainer}>
      <div className="flex al-ce row jc">
        <div className={styles.imgBg}>
          <div className={styles.purpleCircle}>
            {link && <img src={purpleLink} alt="link" />}
          </div>
        </div>
        <div className={styles.nameCol}>
          <div className="flex row al-ce">
            <span className={styles.fn}>{firstName}</span>
            <div className={styles.sn}>{lastName}</div>
          </div>
          <div className="flex row al-ce">
            <img className={styles.fileIcon} src={FileIcon} alt="file" />
            <a href="/" className={styles.seeContract}>
              see the contract
            </a>
          </div>
        </div>
      </div>

      {!hideScore ? <Score score={score} /> : null}
    </div>
  );
}
