import React, { useEffect, useState } from "react";
import HomeHeader from "../components/HomeHeader";
import MobNavbar from "../components/MobNavbar";
import { useMediaQuery } from "react-responsive";
import { format as formatTimeago } from "timeago.js";

import styles from "../styles/HoldersInfo.module.css";
import InfoRow from "../components/HoldersInfo/InfoRow";

import pinkTick from "../assets/images/pinkTick.png";
import minusBullet from "../assets/images/minusBullet.png";
import { shortenWalletAddress } from "../utils/shortenWallet";
import Search from "../components/Search";
import { useQuery } from "@tanstack/react-query";
import Breadcrumbs from "../components/Breadcrumbs";
import Back from "../components/Back";
import { ArrowLeft } from "../assets/Svg";
import { useRecoilState, useRecoilValue } from "recoil";
import { tokenSnifferAddressState } from "../recoil/search";
import { useNavigate, useSearchParams } from "react-router-dom";

const path = [
  { label: "Home", url: "/" },
  { label: "Honeypot Checker", url: "/" },
  { label: "Token Sniffer", url: "/token-sniffer" },
];

export default function TokenSniffer() {
  const isMobile = useMediaQuery({
    maxWidth: 768,
  });
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const addressQuery = queryParams.get("address");
  const [addressSearch, setAddressSearch] = useRecoilState(
    tokenSnifferAddressState
  );
  const [currentAddress, setCurrentAddress] = useState(addressQuery);

  const { data, isSuccess, isError, error } = useQuery({
    queryKey: [`/token/${currentAddress}?history=day`],
  });

  useEffect(() => {
    if (addressQuery) {
      setCurrentAddress(addressQuery);
      setAddressSearch(addressQuery);
    }
  }, [addressQuery]);

  const handleSearchChange = (e) => {
    setAddressSearch(e.target.value);
  };

  const handleSearch = () => {
    if (addressSearch) {
      navigate(`/token-sniffer?address=${addressSearch}`);
    }
  };

  let formattedContractAnalysis1 = [];
  let formattedContractAnalysis2 = [];
  let formattedLiquidityAnalysis1 = [];

  if (isSuccess) {
    if (data?.analysis?.contract) {
      formattedContractAnalysis1 = formatAnalysisData(contractAnalysis1, [
        !data.analysis.contract.verifiedSource ||
          data.analysis.contract.verifiedSource === "N/A",
        !data.analysis.contract.ownershipRenounced ||
          data.analysis.contract.ownershipRenounced === "N/A",
      ]);
    }
    if (data?.analysis?.holders) {
      formattedContractAnalysis2 = formatAnalysisData(contractAnalysis2, [
        !data.analysis.holders.creatorHoldsLittle ||
          data.analysis.holders.creatorHoldsLittle === "N/A",
        !data.analysis.holders.othersHoldLittle ||
          data.analysis.holders.othersHoldLittle === "N/A",
      ]);
    }
    console.log(
      !data.analysis.liquidity?.poolCount ||
        data.analysis.liquidity?.poolCount < 1
    );
    if (data?.analysis?.liquidity) {
      formattedLiquidityAnalysis1 = formatAnalysisData(liquidityAnalysis, [
        data.analysis.liquidity?.poolCount &&
          data.analysis.liquidity?.poolCount < 1,
      ]);
    }
  }

  return (
    <div className={styles.container}>
      <HomeHeader />
      <MobNavbar />
      <div className={styles.bodyContainer}>
        <div className={`flex sb al-ce back-component`}>
          <Back path={"/search-token-sniffer"} />

          {!isMobile && <Breadcrumbs path={path} />}
        </div>
        <div className={styles.topSection}>
          <span className={styles.hero}>token sniffer</span>
          <span className={styles.subHeader}>honeypot checker</span>
        </div>
        <Search
          search={addressSearch}
          setSearch={handleSearchChange}
          placeholder={"0x000000"}
          onSearchClick={handleSearch}
        />
        <div className={styles.header}>token sniffer</div>
        {isSuccess && (
          <>
            <InfoRow
              firstName={data.name}
              lastName={data.symbol}
              score={data?.score || 0}
              link
            />

            <div className={styles.section}>
              <div className={styles.detailsRow}>
                <div className={styles.normalText}>charts</div>
                <div className={styles.charts}>
                  <a>DEX Screener</a>
                  <a>GeckoTerminal</a>
                  <a>PooCoin</a>
                  <a>DEXTools</a>
                </div>
              </div>
              <div className={styles.detailsRow}>
                <div className={styles.normalText}>deployed</div>
                <div className={styles.normalText}>
                  {data.deployedAt} ({formatTimeago(data.deployedAt)})
                </div>
              </div>
              <div className={styles.detailsRow}>
                <div className={styles.normalText}>address</div>
                <div className={styles.normalText}>
                  {isMobile ? shortenWalletAddress(data.address) : data.address}
                </div>
              </div>
            </div>
            <div className={styles.section}>
              <span className={styles.header}>
                score: {data?.score || 0}/100
              </span>
              <div className={styles.scoreDesc}>
                The audit score is a measure of how well the token meets the
                criteria for safety. Automated scanners like this one are not
                always completely accurate. A token with a high score may still
                have hidden malicious code. The score is not advice and should
                be considered along with other factors. Always do your own
                research and consult multiple sources of information. Results
                are regenerated every 60 minutes.
              </div>
            </div>
            {formattedContractAnalysis1.length > 0 && (
              <div className={styles.section}>
                <span className={styles.header}>contract analysis</span>
                <div className={styles.subSection}>
                  {formattedContractAnalysis1.map((point, i) => (
                    <div key={i} className={styles.bulletRow}>
                      <img
                        className={styles.pinkTick}
                        src={pinkTick}
                        alt="bullet"
                      />
                      <div className={styles.normalText}>{point}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {formattedContractAnalysis2.length > 0 && (
              <div className={styles.section}>
                <span className={styles.header}>contract analysis</span>
                <div className={styles.subSection}>
                  {formattedContractAnalysis2.map(({ content, sub }, i) => (
                    <div key={i} className={styles.bulletRow}>
                      <img
                        className={styles.minusBullet}
                        src={minusBullet}
                        alt="bullet"
                      />

                      <div className="flex col sa">
                        <div className={styles.normalText}>{content}</div>
                        <div className={styles.descText}>{sub}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {formattedLiquidityAnalysis1.length > 0 && (
              <div className={styles.section}>
                <span className={styles.header}>Liquidity analysis</span>
                <div className={styles.note}>
                  Please note: Uniswap v3 is currently NOT supported but support
                  will be added in the near future.
                </div>
                <div className={styles.subSection}>
                  {formattedLiquidityAnalysis1.map(({ content, sub }, i) => (
                    <div key={i} className={styles.bulletRow}>
                      <img
                        className={styles.minusBullet}
                        src={minusBullet}
                        alt="bullet"
                      />

                      <div className="flex col sa">
                        <div className={styles.normalText}>{content}</div>
                        <div className={styles.descText}>{sub}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

const formatAnalysisData = (data, conditionsArray) => {
  let result = [];
  data?.forEach((analysis, i) => {
    if (!conditionsArray[i]) result.push(data[i]);
  });
  return result;
};

let contractAnalysis1 = [
  "verified contract source",
  "Ownership renounced or source does not contain an owner contract",
];

let contractAnalysis2 = [
  {
    content:
      "Creator wallet contains less than 5% of circulating token supply (90%)",
    sub: "The creator wallet contains a substantial amount of tokens which could have a large impact on the token price if sold.",
  },
  {
    content:
      "All other holders possess less than 5% of circulating token supply",
    sub: "All other holders possess less than 5% of circulating token supply",
  },
];

let liquidityAnalysis = [
  {
    content: "Liquidity pool not found (ignore for presale)",
    sub: "A liquidity pool was not found making it impossible to trade the token (ignore for presale).",
  },
];
