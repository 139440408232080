import React from "react";
import HomeHeader from "../components/HomeHeader";
import MobNavbar from "../components/MobNavbar";

import backStyles from "../styles/Analysis.module.css";
import styles from "../styles/HoldersTransactions.module.css";
import TransactionRow from "../components/HoldersInfo/TransactionRow";
import { useQuery } from "@tanstack/react-query";
import { ArrowLeft } from "../assets/Svg";
import Breadcrumbs from "../components/Breadcrumbs";
import { useMediaQuery } from "react-responsive";
import Back from "../components/Back";

const path = [
  { label: "Home", url: "/" },
  { label: "Honeypot Checker", url: "/" },
  { label: "holders", url: "/holders" },
];

export default function HoldersTransactions() {
  const isMobile = useMediaQuery({
    maxWidth: 768,
  });
  const { data, isSuccess, isError, error } = useQuery({
    queryKey: ["/holders/nft?order=desc"],
  });

  return (
    <div className={styles.container}>
      <HomeHeader />
      <MobNavbar />
      <div className={styles.bodyContainer}>
        <div className={`flex sb al-ce back-component`}>
          <Back path={"/choose"} />

          {!isMobile && <Breadcrumbs path={path} />}
        </div>
        <div className={styles.header}>pepeai holders</div>
        <div className={styles.gridContainer}>
          <div className={styles.headersGrid}>
            <div>No.</div>
            <div>holder adress</div>
            <div>holding amount</div>
            <div>PNL</div>
          </div>
          {data?.map((holder, i) => (
            <TransactionRow
              key={holder.address}
              serial={i + 1}
              address={holder.address}
              amount={holder.balance}
              pnl={10}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
