import { useState } from "react";
import "./App.css";
import Content from "./components/Content";
import MobDrawer from "./components/MobDrawer";
import Sidebar from "./components/Sidebar";
import { useEffect } from "react";
import Loader from "./components/Loader";
import { AnimatePresence, motion } from "framer-motion";
import MusicModal from "./components/MusicModal";
import useSound from "use-sound";
import Audio from "./assets/audio/bg.aac";
import { useSetRecoilState } from "recoil";
import { isMusicModalOpen } from "./recoil";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";

function App() {
  const [loading, setLoading] = useState(true);
  const setVisible = useSetRecoilState(isMusicModalOpen);
  const [play, { pause }] = useSound(Audio, {
    loop: true,
    onload: () => {
      setLoading(false);
    },
  });
  const location = useLocation();

  const isComingSoonPage = location.pathname === "/coming-soon";


  useEffect(() => {
    let pop_status = localStorage.getItem("pop_status");
    if (!pop_status) {
      setVisible(true);
      localStorage.setItem("pop_status", 1);
    }
  }, [setVisible]);

  return (
    <>
      <ToastContainer />
      <AnimatePresence>
        {loading && (
          <motion.div
            exit={{ opacity: 0 }}>
            <Loader />
          </motion.div>
        )}
      </AnimatePresence>

      <div className="container">
        {/* <div className={`bg ${isComingSoonPage ? "coming-soon-bg" : ""}`} /> */}
        {/* <div className="blur" /> */}
        <Sidebar />
        <Content />
        <MobDrawer />
        <MusicModal play={play} pause={pause} />
      </div>
    </>
  );
}

export default App;
